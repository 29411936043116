.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 8px 16px;
}

.App-link {
  color: #61dafb;
}

.App-body {
  display: grid;
  grid-template-columns: 20vw auto;
}

.App-body-sidebar {
  word-wrap: break-word;
}

.App-body-content__wrapper {
  display: flex;
  justify-content: center;
  padding: 24px 32px;
}

.App-body-content {
  background: gray;
  color: white;
  width: fit-content;
  height: fit-content;
  padding: 8px 16px;
  border-radius: 6px;
}